<template>
  <div class="report-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>报告</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <ul class="report-header">
        <li>
          <router-link :to="{name: 'HomeReport'}">报告列表</router-link>
        </li>
        <li>
          <router-link :to="{name: 'HomeReportCustomer'}">顾客报告</router-link>
        </li>
      </ul>
      <router-view></router-view>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';

export default {
	components: { 'v-title': vTitle },
};
</script>

<style lang="less" scoped>
.report-header {
  display: flex;
  a {
    width: 90px;
    font-size: 16px;
    text-align: center;
    position: relative;
    display: block;
    color: #000;
    z-index: 1;
    span {
      cursor: pointer;
      background-color: transparent;
    }
    &.active {
      &::before {
        content: '';
        background-color: #fdad00;
        height: 10px;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
    }
  }
}
</style>
